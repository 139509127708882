<ion-menu side="start" contentId="content" menuId="listSimulations" name="listSimulations" *ngIf="checkSidebar()">
  <ion-content>
    <div class="header">
      <h4 [innerHTML]="'SIDEBAR.TITLE' | translate">Vos simulations</h4>
    </div>
    <ul class="list">
      <li *ngFor="let simulation of _simulations.groupOfSimulations">
        <div class="begin">
          <ion-icon name="radio-button-on" *ngIf="simulation.select" (click)="selectSimulation(simulation)" [ngStyle]="{color : simulation.genericInformations.color}"></ion-icon>
          <ion-icon name="radio-button-off" *ngIf="!simulation.select" (click)="selectSimulation(simulation)" [ngStyle]="{color : simulation.genericInformations.color}"></ion-icon>    
        </div>
        <div class="content">
            <div class="subMenu">
                <ion-icon name="eye" (click)="viewSimulation(simulation)" *ngIf="!_simulations.viewSimulation || _simulations.viewSimulation.id!=simulation.id"></ion-icon>
                <ion-icon name="eye-off" class="danger" (click)="noEditSimulation(simulation)" *ngIf="_simulations.viewSimulation && _simulations.viewSimulation.id===simulation.id"></ion-icon>
                <ion-icon name="create" (click)="editSimulation(simulation)" *ngIf="!_simulations.tmpSimulation || _simulations.tmpSimulation.id!=simulation.id"></ion-icon>
                <ion-icon name="checkmark" class="success" (click)="noEditSimulation(simulation)" *ngIf="_simulations.tmpSimulation && _simulations.tmpSimulation.id===simulation.id"></ion-icon>
                <ion-icon name="build" (click)="paramsSimulation(simulation)"></ion-icon>
                <ion-icon name="trash" (click)="deleteSimulation(simulation)"></ion-icon>
            </div>
            <ion-label [ngStyle]="{color : simulation.genericInformations.color}" *ngIf="!_simulations.tmpSimulation || _simulations.tmpSimulation.id!=simulation.id">{{simulation.genericInformations.name}}</ion-label>
            <div *ngIf="_simulations.tmpSimulation && _simulations.tmpSimulation.id===simulation.id" class="label">
              <app-editForm
              [genericInformations] = "_simulations.tmpSimulation"
              [type] = "'pop'"></app-editForm>
            </div>
            <div *ngIf="_simulations.viewSimulation && _simulations.viewSimulation.id===simulation.id">
              <div class="accordion-list">

                <div class="accordion-item" [ngClass]="{'open': accordionOpen=='initial-state', 'close': accordionOpen!='initial-state' }">
                  <div class="accordion-header" (click)="changeAccordion('initial-state')">
                      <ion-icon name="arrow-dropright" *ngIf="accordionOpen!='initial-state'"></ion-icon>
                      <ion-icon name="arrow-dropdown" *ngIf="accordionOpen=='initial-state'"></ion-icon>
                      <h4 [innerHTML]="'SIDEBAR.INITIAL_STATE.TITLE' | translate">État initial</h4>
                  </div>
                  <div class="accordion-content">
                    <ul>
                        <li><span [innerHTML]="'SIDEBAR.INITIAL_STATE.LIST.STATE' | translate"></span>: <strong>{{simulation.genericInformations.startYear}}</strong></li>
                    </ul>
                  </div>
                </div>

                  <div class="accordion-item" [ngClass]="{'open': accordionOpen=='astronomical', 'close': accordionOpen!='astronomical' }">
                    <div class="accordion-header" (click)="changeAccordion('astronomical')">
                        <ion-icon name="arrow-dropright" *ngIf="accordionOpen!='astronomical'"></ion-icon>
                        <ion-icon name="arrow-dropdown" *ngIf="accordionOpen=='astronomical'"></ion-icon>
                        <h4 [innerHTML]="'SIDEBAR.ASTRONOMICAL.TITLE' | translate">Paramètres astronomiques</h4>
                    </div>
                    <div class="accordion-content">
                      <ul>
                          <li><span [innerHTML]="'SIDEBAR.ASTRONOMICAL.LIST.DISTANCE_EARTH_SUN' | translate"></span>: <strong>{{_simulations.viewSimulation.parameters.distance_ts_value}}</strong></li>
                          <li><span [innerHTML]="'SIDEBAR.ASTRONOMICAL.LIST.POWER_OF_THE_SUN' | translate"></span>: <strong>{{_simulations.viewSimulation.parameters.puissance_soleil_value}}</strong></li>
                          <li><span [innerHTML]="'SIDEBAR.ASTRONOMICAL.LIST.ECCENTRICITY' | translate"></span>: <strong>{{_simulations.viewSimulation.parameters.excentricite_value}}</strong></li>
                          <li><span [innerHTML]="'SIDEBAR.ASTRONOMICAL.LIST.OBLIQUITY' | translate"></span>: <strong>{{_simulations.viewSimulation.parameters.obliquite_value}}</strong></li>
                          <li><span [innerHTML]="'SIDEBAR.ASTRONOMICAL.LIST.PRECESSION' | translate"></span>: <strong>{{_simulations.viewSimulation.parameters.precession_value}}</strong></li>
                      </ul>
                    </div>
                  </div>
                  <div class="accordion-item" [ngClass]="{'open': accordionOpen=='co2-emissions', 'close': accordionOpen!='co2-emissions' }">
                    <div class="accordion-header" (click)="changeAccordion('co2-emissions')">
                        <ion-icon name="arrow-dropright" *ngIf="accordionOpen!='co2-emissions'"></ion-icon>
                        <ion-icon name="arrow-dropdown" *ngIf="accordionOpen=='co2-emissions'"></ion-icon>
                        <h4 [innerHTML]="'SIDEBAR.CO2_EMISSIONS.TITLE' | translate">Emissions de CO2</h4>
                    </div>
                    <div class="accordion-content">
                        <ul>
                            <li><span [innerHTML]="'SIDEBAR.CO2_EMISSIONS.LIST.FIXED_CONCENTRATION' | translate"></span>: <strong>{{_simulations.viewSimulation.parameters.fixed_concentration}}</strong></li>
                            <li><span [innerHTML]="'SIDEBAR.CO2_EMISSIONS.LIST.CONCENTRATION' | translate"></span>: <strong>{{_simulations.viewSimulation.parameters.coo_concentr_value}}</strong></li>
                            <li><span [innerHTML]="'SIDEBAR.CO2_EMISSIONS.LIST.EMISSION_ANTHROPOGENIC' | translate"></span>: <strong>{{_simulations.viewSimulation.parameters.emit_anthro_coo_value}}</strong></li>
                            <li><span [innerHTML]="'SIDEBAR.CO2_EMISSIONS.LIST.VOLCANISM' | translate"></span>: <strong>{{_simulations.viewSimulation.parameters.volcan_value}}</strong></li>
                            <li><span [innerHTML]="'SIDEBAR.CO2_EMISSIONS.LIST.BIOLOGICAL_STORAGE' | translate"></span>: <strong>{{_simulations.viewSimulation.parameters.stockage_biologique_value}}</strong></li>
                            <li><span [innerHTML]="'SIDEBAR.CO2_EMISSIONS.LIST.CONTINENTAL_ALTERATION' | translate"></span>: <strong>{{_simulations.viewSimulation.parameters.alteration_value}}</strong></li>
                        </ul>
                    </div>
                  </div>
                  <div class="accordion-item" [ngClass]="{'open': accordionOpen=='retro-climate', 'close': accordionOpen!='retro-climate' }">
                    <div class="accordion-header" (click)="changeAccordion('retro-climate')">
                        <ion-icon name="arrow-dropright" *ngIf="accordionOpen!='retro-climate'"></ion-icon>
                        <ion-icon name="arrow-dropdown" *ngIf="accordionOpen=='retro-climate'"></ion-icon>
                        <h4 [innerHTML]="'SIDEBAR.RETRO_CLIMATE.TITLE' | translate">Rétroaction</h4>
                    </div>
                    <div class="accordion-content">
                        <ul>
                          <li><span [innerHTML]="'SIDEBAR.RETRO_CLIMATE.LIST.FIXED_ALBEDO' | translate"></span>: <strong>{{_simulations.viewSimulation.parameters.fixed_albedo}}</strong></li>
                          <li><span [innerHTML]="'SIDEBAR.RETRO_CLIMATE.LIST.ALBEDO' | translate"></span>: <strong>{{_simulations.viewSimulation.parameters.albedo_value}}</strong></li>
                          <li><span [innerHTML]="'SIDEBAR.RETRO_CLIMATE.LIST.FIXED_OCEAN' | translate"></span>: <strong>{{_simulations.viewSimulation.parameters.fixed_ocean}}</strong></li>
                          <li><span [innerHTML]="'SIDEBAR.RETRO_CLIMATE.LIST.DEBRANCHE_OCEAN' | translate"></span>: <strong>{{_simulations.viewSimulation.parameters.debranche_ocean}}</strong></li>
                          <li><span [innerHTML]="'SIDEBAR.RETRO_CLIMATE.LIST.OCEAN' | translate"></span>: <strong>{{_simulations.viewSimulation.parameters.puit_oce_value}}</strong></li>
                          <li><span [innerHTML]="'SIDEBAR.RETRO_CLIMATE.LIST.DEBRANCHE_VEGETATION' | translate"></span>: <strong>{{_simulations.viewSimulation.parameters.debranche_biologie}}</strong></li>
                          <li><span [innerHTML]="'SIDEBAR.RETRO_CLIMATE.LIST.VEGETATION' | translate"></span>: <strong>{{_simulations.viewSimulation.parameters.puit_bio_value}}</strong></li>
                          <li><span [innerHTML]="'SIDEBAR.RETRO_CLIMATE.LIST.FIXED_WATER_VAPOUR' | translate"></span>: <strong>{{_simulations.viewSimulation.parameters.fixed_eau}}</strong></li>
                          <li><span [innerHTML]="'SIDEBAR.RETRO_CLIMATE.LIST.WATER_VAPOUR' | translate"></span>: <strong>{{_simulations.viewSimulation.parameters.rapport_H2O_value}}</strong></li>
                        </ul>
                    </div>
                  </div>
              </div>
            </div>
        </div>
      </li>
    </ul>
  </ion-content>
</ion-menu>
<ion-app>
  <ion-router-outlet #content id="content"></ion-router-outlet>
</ion-app>
