import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SingleViewComponent } from './single-view/single-view.component'
import { TranslateModule } from '@ngx-translate/core';
import { IonicModule } from '@ionic/angular';
import { ShareModule } from '../share/share.module';


@NgModule({
  declarations: [
    SingleViewComponent,
  ],
  imports: [
    ShareModule,
    IonicModule,
    FormsModule,
    CommonModule,
    TranslateModule.forChild()
  ],
  exports: [
    SingleViewComponent,
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class EditFormModule { }
