import { Component, OnInit, OnChanges, Input, ElementRef, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../../share/language.service';
import { SimulationsService } from '../../share/simulations/simulations.service';
import { simulationModel } from '../../share/simulations/simulations.model';
import isElectron from 'is-electron';

@Component({
  selector: 'app-schema-water',
  templateUrl: './water.component.html',
  styleUrls: ['./water.component.scss'],
})
export class WaterComponent implements OnInit {

  @Input() year;

  @ViewChild('img') imgElement: ElementRef;
  @ViewChild('schema') schemaElement: ElementRef;

  imageDirectory : string;

  arrayTemperature: {data?: any, percent?: any, color?: any, label?: any}[] = [];
  constructor(public translate: TranslateService,
    public _language: LanguageService,
    public _simulations: SimulationsService) {
      translate.addLangs(['en', 'fr', 'es', 'de']);
      this._language.setInitialAppLanguage();

      if (isElectron())
      {
        this.imageDirectory = './assets/imgs/animations/';
      }
      else
      {
        this.imageDirectory = '/assets/imgs/animations/';
      }
  }

  ngOnInit() {
  }
  ngOnChanges(changes){
    this._simulations.schemaTemperatureByDate();
    this._simulations.schemaWaterByDate(); 
  }

  calculCss(){
    let returnCss;
    if(this.schemaElement && this.schemaElement.nativeElement){
      if(this.schemaElement.nativeElement.offsetHeight > this.schemaElement.nativeElement.offsetWidth){
        if(this.schemaElement.nativeElement.offsetWidth/1.4 < this.schemaElement.nativeElement.offsetHeight) {
          returnCss = {
            'margin-top': ((this.schemaElement.nativeElement.offsetHeight-(this.schemaElement.nativeElement.offsetWidth/1.4))/2)+'px',
            'height' : (this.schemaElement.nativeElement.offsetWidth/1.4)+'px',
            'width' : (this.schemaElement.nativeElement.offsetWidth)+'px',
          }
        }else{
          returnCss = {
            'margin-top': 'Opx',
            'height' : (this.schemaElement.nativeElement.offsetHeight)+'px',
            'width' : (this.schemaElement.nativeElement.offsetHeight*1.4)+'px',
          }
        }
      }else{
        if(this.schemaElement.nativeElement.offsetHeight*1.4 < this.schemaElement.nativeElement.offsetWidth) {
          returnCss = {
            'margin-top': 'Opx',
            'height' : (this.schemaElement.nativeElement.offsetHeight)+'px',
            'width' : (this.schemaElement.nativeElement.offsetHeight*1.4)+'px',
          }
        }
        else{
          returnCss = {
            'margin-top': ((this.schemaElement.nativeElement.offsetHeight-(this.schemaElement.nativeElement.offsetWidth/1.4))/2)+'px',
            'height' : (this.schemaElement.nativeElement.offsetWidth/1.4)+'px',
            'width' : (this.schemaElement.nativeElement.offsetWidth)+'px',
          }
        }
      }
    }
    return returnCss;
  }

}
