import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SingleViewComponent } from './single-view/single-view.component'
import { ShareModule } from '../share/share.module';



@NgModule({
  declarations: [
    SingleViewComponent
  ],
  imports: [
    ShareModule,
    CommonModule
  ],
  exports: [
    SingleViewComponent
  ]
})
export class ContentsTextModule { }
