import { Component, OnInit, Input } from '@angular/core';
import { ContentsTextService } from '../contents-text.service'

@Component({
  selector: 'app-pages',
  templateUrl: './single-view.component.html',
  styleUrls: ['./single-view.component.scss'],
})
export class SingleViewComponent implements OnInit {

  @Input() page: string = null;

  datasPage : any = {
    "content" : null,
    "content_html" : null
  };

  constructor( public _pagesService: ContentsTextService) {
  }

  ngOnInit() {
    this._pagesService.getContent().subscribe((s) => {
      if(s && s[this.page]){
        this.datasPage = s[this.page];
      }else{
        this.datasPage = null;
      }
    }, (e) => { 
      this.datasPage = null;
    });
  }

}
