import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { SimulationParametersPage } from './pages/simulation-parameters/simulation-parameters.page'

const routes: Routes = [
  { path: 'home-page', loadChildren: () => import('./pages/home-page/home-page.module').then(m => m.HomePagePageModule) },
  { path: 'new-simulations', loadChildren: () => import('./pages/new-simulations/new-simulations.module').then(m => m.NewSimulationsPageModule) },
  { path: 'simulation-parameters', loadChildren: () => import('./pages/simulation-parameters/simulation-parameters.module').then(m => m.SimulationParametersPageModule) },
  { path: 'simulations-visualization', loadChildren: () => import('./pages/simulations-visualization/simulations-visualization.module').then(m => m.SimulationsVisualizationPageModule) },
  { path: 'legal-notice', loadChildren: () => import('./pages/legal-notice/legal-notice.module').then(m => m.LegalNoticePageModule) },
  { path: 'credits', loadChildren: () => import('./pages/credits/credits.module').then(m => m.CreditsPageModule) },
  { path: 'documentation', loadChildren: () => import('./pages/documentation/documentation.module').then(m => m.DocumentationPageModule) },
  {
    path: '',
    redirectTo: 'home-page',
    pathMatch: 'prefix'
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, useHash: true, enableTracing: false })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
