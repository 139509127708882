import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule, HttpClient } from '@angular/common/http';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppRoutingModule } from './app-routing.module';
import { ContentsTextModule } from './contents-text/contents-text.module';
import { FaqsModule } from './faqs/faqs.module';
import { EditFormModule } from './edit-form/edit-form.module';
import { SavedStateModule } from './saved-state/saved-state.module';
import { SchemaModule } from './schema/schema.module';
import { ShareModule } from './share/share.module';
import { AppComponent } from './app.component';

import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { ExportAsModule } from 'ngx-export-as';
import { MomentModule } from 'ngx-moment';
import { FormsModule } from '@angular/forms';
import isElectron from 'is-electron';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';

import { File } from '@ionic-native/file/ngx';

export function HttpLoaderFactory(http: HttpClient) {
  if (isElectron())
  {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
  }
  else
  {
    return new TranslateHttpLoader(http, '../assets/i18n/', '.json');
  }
}

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        HttpClientModule,
        BrowserModule,
        BrowserAnimationsModule,
        ContentsTextModule,
        FormsModule,
        EditFormModule,
        FaqsModule,
        SavedStateModule,
        ShareModule,
        MomentModule,
        SchemaModule,
        ExportAsModule,
        IonicStorageModule.forRoot(),
        IonicModule.forRoot({_forceStatusbarPadding: true}),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        AppRoutingModule
    ],
    providers: [
        StatusBar,
        SplashScreen,
        InAppBrowser,
        File,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
