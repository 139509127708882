import { extend } from 'webdriver-js-extender';
import { Moment } from 'moment';
import { CSimulationValues } from 'simclimat-lib';

export class sessionModel {
    public sessionTimestamp?: string;
    public sessionSimulations: simulationModel[];

    constructor(
        sessionTimestamp?: string,
        sessionSimulations?: simulationModel[],
 ) {
    }
}

export class graphModel {
    public labels?: string[];
    public datasets?: {}[];
    public options?: {};

    constructor(labels?: string[],
                datasets?: {}[],
                options?: {},
 ) {
    }
}

export class infoGenericSimulation {
    public nbrYears?: number;
    public historyNbrYears?: number;
    public step?: number;
    public startYear?: any;
    public endYear?: any;
    public historyStartYear?: any;
    public createDate?: Moment;
    public name?: string;
    public color?: string;

    constructor(nbrYears?: number,
                step?: number,
                startYear?: number,
                createDate?: Moment,
                name?: string,
                color?: string,
                endYear?: number
                ) {
    }
}

export class simulationModel {
    public id?: number;
    public state?: string;
    public select?: boolean;
    public isChecked?: boolean;
    public genericInformations?: infoGenericSimulation;
    public parameters?: simulationParametersModel;
    public results?: simulationResultsModel;

    constructor(id?: number,
                state?: string,
                select?: boolean,
                genericInformations?: infoGenericSimulation,
                parameters?: simulationParametersModel,
                results?: any
                ) {
    }
}

export class simulationParametersModel {
    // Simulation name
        public simulation_name?: string;
        public simulation_color?: string;
        public annee_debut?: number;
        public annee_fin?: number;

	// emission_dialog
        public emit_anthro_coo_value?: number|string;
        public volcan_value?: number|string;
        public alteration_value?: number|string;
        public stockage_biologique_value?: number|string;

    // CO2 emissions
        public fixed_concentration?: boolean;
	    public coo_concentr_value?: number|string;

    // Astronomical
        // Distance earth-sun
        public distance_ts_value?: number|string;

        // Eccentricity
        public excentricite_value?: number|string;

        // Obliquity
        public obliquite_value?: number|string;

        // Power of the sun
        public puissance_soleil_value?: number|string;

        // Precession
        public precession_value?: number|string;

	// Retro-climate
        // albedo
        public fixed_albedo?: boolean;
        public albedo_value?: number|string;

        // ocean
        public debranche_ocean?: boolean;
        public fixed_ocean?: boolean;
        public puit_oce_value?: number|string;

        // vegetation
        public debranche_biologie?: boolean;
        public puit_bio_value?: number|string;

        // vapeur d'eau
        public fixed_eau?: boolean;
        public rapport_H2O_value?: number|string;

    constructor(
        simulation_name?: string,
	       annee_debut?: number|string,
        fixed_concentration?: boolean,
	       coo_concentr_value?: number|string,
        distance_ts_value?: number|string,
        excentricite_value?: number|string,
        obliquite_value?: number|string,
        puissance_soleil_value?: number|string,
        precession_value?: number|string,
        fixed_albedo?: boolean,
        albedo_value?: number|string,
        debranche_ocean?: boolean,
        fixed_ocean?: boolean,
        puit_oce_value?: number|string,
        debranche_biologie?: boolean,
        puit_bio_value?: number|string,
        fixed_eau?: boolean,
        rapport_H2O_value?: number|string,
        annee_fin?: number|string
    ) { }
}

export class simulationResultsModel {
        public emissions_coo_data?: any;
        public concentrations_coo_data?: any;
        public temperature_data?: any;
        public niveau_calottes_data?: any;
        public niveau_mer_data?: any;
        public albedo_data?: any;

    constructor(
        emissions_coo_data?: any,
        concentrations_coo_data?: any,
        temperature_data?: any,
        niveau_calottes_data?: any,
        niveau_mer_data?: any,
        albedo_data?: any
    ) { }
}

export class listOfsimulationsModel {
    public simulations?: simulationModel[];

    constructor(simulations?: simulationModel[],
                ) {
    }
}

export class groupOfsimulationsModel {
    public simulations?: simulationModel[];
    public genericInformations?: infoGenericSimulation;

    constructor(simulations?: simulationModel[],
                genericInformations?: infoGenericSimulation,
                ) {
    }
}
