import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';


import { WaveComponent } from './wave/wave.component';
import { IonicStorageModule } from '@ionic/storage';

@NgModule({
  declarations: [WaveComponent],
  imports: [
    IonicStorageModule,
    CommonModule,
  ],
  exports: [WaveComponent]
})
export class ShareModule { }