import { Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { HttpClient,HttpHeaders, HttpRequest } from '@angular/common/http';
import isElectron from 'is-electron';

 
const LNG_KEY = 'SELECTED_LANGUAGE';
 
@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  selected = 'fr';
  urlFileDocumentation: string = null;
 
  constructor(public translate: TranslateService, 
    public storage: Storage, 
    public plt: Platform,
    public _http: HttpClient) { }
 
  setInitialAppLanguage() {
    let language = this.translate.getBrowserLang();
    this.translate.setDefaultLang(language);
 
    this.storage.get(LNG_KEY).then(val => {
      if (val) {
        this.setLanguage(val);
      }else{
        this.setLanguage(language);
      }
    });
  }
  
 
  getLanguages() {
    if (isElectron())
    {
      return this._http.get("./assets/i18n/languages.electron.json");
    }
    else
    {
      return this._http.get("./assets/i18n/languages.json");
    }
  }
 
  setLanguage(lng) {
    this.translate.setDefaultLang(lng);
    this.translate.use(lng);
    this.selected = lng;
    this.setUrlFileDocumentation();
    this.storage.set(LNG_KEY, lng);
  }

  setUrlFileDocumentation(){
    let doc_file: string;

    if (isElectron())
    {
      doc_file  = './assets/i18n/doc_files.electron.json';
    }
    else
    {
      doc_file  = './assets/i18n/doc_files.json';

    }
    this._http.get(doc_file).subscribe((f) => {
      if(f[this.selected]!=null){
        this.urlFileDocumentation = f[this.selected];
      }else{
        this.urlFileDocumentation = f['en'];
      }
    });
  }
}