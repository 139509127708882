<ion-list *ngIf="listSavedSimulations">
  <ion-item (click)="chooseSavedSimulation.emit(simulation)" *ngFor="let simulation of listSavedSimulations">
    <ion-label>
      <h2>{{simulation.genericInformations.name}}</h2>
      <p>{{simulation.genericInformations.createDate | amDateFormat: 'YYYY-MM-DD HH:mm'}}</p>
    </ion-label>
  </ion-item>
</ion-list>
<ion-list *ngIf="!listSavedSimulations">
  <ion-item>
    Aucune sauvegarde
  </ion-item>
</ion-list>
