import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SingleViewComponent } from './single-view/single-view.component'
import { TranslateModule } from '@ngx-translate/core';
import { ShareModule } from '../share/share.module';



@NgModule({
  declarations: [
    SingleViewComponent,
  ],
  imports: [
    ShareModule,
    CommonModule,
    TranslateModule.forChild()
  ],
  exports: [
    SingleViewComponent
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class FaqsModule { }
