import { Component } from '@angular/core';

import { NavController, Platform, MenuController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Storage } from '@ionic/storage';

import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from './share/language.service';
import { SimulationsService } from './share/simulations/simulations.service';
import { simulationModel } from './share/simulations/simulations.model';

import { CLogger } from 'simclimat-lib';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})

export class AppComponent {

  editThisSimulation: simulationModel = null;
  accordionOpen: string = null;

  constructor(
    public platform: Platform,
    public splashScreen: SplashScreen,
    public statusBar: StatusBar,
    public languageService: LanguageService,
    public translate: TranslateService,
    public _menu: MenuController,
    public _simulations: SimulationsService,
    public _nav: NavController,
    public _storage: Storage
  ) {
    CLogger.setDebug(!environment.production);
    translate.addLangs(['en', 'fr', 'es', 'de']);
    this.initializeApp();
  }

  initializeApp() {
    this._nav.navigateForward('/home-page');
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      this.languageService.setInitialAppLanguage();
      this._storage.remove('lastSimulation');
    });
  }

  selectSimulation(simulation) {
    simulation.select = !simulation.select;
    this._simulations.generateGraph();
    return simulation;
  }

  editSimulation(simulation) {
    this._simulations.tmpSimulation = simulation;
  }

  viewSimulation(simulation) {
    this._simulations.tmpSimulation = null;
    this.accordionOpen = null;
    if (this._simulations.viewSimulation === simulation) {
      this._simulations.viewSimulation = null;
    } else {
      this._simulations.viewSimulation = simulation;
    }
  }
  
  noEditSimulation() {
    this._simulations.tmpSimulation = null;
    this._simulations.viewSimulation = null;
    this._simulations.generateGraph();
  }

  paramsSimulation(simulation) {
    this._simulations.tmpSimulation = simulation;
    this._simulations.viewSimulation = null;
    this._nav.navigateForward('/simulation-parameters/astronomical');
    this._menu.close('listSimulations');
  }

  deleteSimulation(simulation) {
    this._simulations.tmpSimulation = simulation;
    this._simulations.viewSimulation = null;
    this._simulations.deleteSimulation();
    // this._menu.close('listSimulations');
  }

  changeAccordion(name) {
    if (this.accordionOpen === name) {
      this.accordionOpen = null;
    } else {
      this.accordionOpen = name;
    }
  }

  checkSidebar() {
    return true;
  }
}
