import { Injectable } from '@angular/core';
import { NavController, LoadingController, MenuController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { CSimulationValues, CModel, CExperienceValues } from 'simclimat-lib';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../../share/language.service';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';

import { ngxCsv } from 'ngx-csv/ngx-csv';
import { graphModel, simulationParametersModel, groupOfsimulationsModel, infoGenericSimulation, simulationModel, simulationResultsModel, sessionModel } from './simulations.model';
import moment from 'moment';
import * as crosshair from 'chartjs-plugin-crosshair';
import * as annotation from 'chartjs-plugin-annotation';
import { Co2EmissionsPageModule } from 'src/app/pages/simulation-parameters/co2-emissions/co2-emissions.module';
import { ChartjsComponent } from '@ctrl/ngx-chartjs';



@Injectable({
  providedIn: 'root'
})
export class SimulationsService {

  constructor(public translate: TranslateService,
              public _http: HttpClient,
              public loadingController: LoadingController,
              public _language: LanguageService,
              public _nav: NavController,
              public _storage: Storage,
              public _menu: MenuController) {
    translate.addLangs(['en', 'fr', 'es', 'de']);
    this._language.setInitialAppLanguage();
  }

  nbrSimulation = 0;

  tmpSimulation: simulationModel = null;
  lastSimulation: simulationModel = null;
  viewSimulation: simulationModel;
  groupOfSimulations: simulationModel[] = [];
  savedSessions: sessionModel[] = [];
  groupOfSaveSimulations: simulationModel[] = [];
  graphAlbedo: graphModel = new graphModel();
  graphEmissionsCoo: graphModel = new graphModel();
  graphConcentrationCoo: graphModel = new graphModel();
  graphTemperature: graphModel = new graphModel();
  graphCalotte: graphModel = new graphModel();
  graphMer: graphModel = new graphModel();
  xPosition: number = null;
  jsonValues: {} = null;


  startYears: number = null;
  stopYears: number = null;

  listColor: string[] = [
    '#F44336',
    '#9C27B0',
    '#3F51B5',
    '#03A9F4',
    '#009688',
    '#8BC34A',
    '#FF9800',
    '#795548',
    '#607D8B',
    '#B0B848',
    '#D4A373',
    '#FF70A6',
    '#FFDC5E',
    '#ECA77F',
    '#61A1D7',
    '#BC0848',
    '#248520',
    '#55D6C2',
    '#F65BE3',
    '#80FF72'
  ];

  public pluginsGraph: any[] = [annotation];
  public optionsGraph: any;


  // SCHEMAS ANIMATION
  arrayTemperature: {data?: any, percent?: any, color?: any, label?: any}[] = [];

  arrayIce: {data?: any, percent?: any, color?: any, label?: any}[] = [];

  arrayWater: {data?: any, percent?: any, color?: any, label?: any}[] = [];
  initOptionsGraph() {
    this.optionsGraph = {
      responsive: false,
      maintainAspectRatio: false,
      animation: {
        duration: 0 // general animation time
      },
      hover: {
        animationDuration: 0 // duration of animations when hovering an item
      },
      responsiveAnimationDuration: 0,
      tooltips: {
        // mode: 'interpolate',
        intersect: false,
        callbacks: {
          title(a, d) {
            // this.xPosition = a[0].xLabel;
            return a[0].label;
          },
          label(i, d) {
            return (
                d.datasets[i.datasetIndex].label + ': ' + i.yLabel.toFixed(2)
            );
          }
        }
      },
      annotation: {
        events: [],
        annotations: [{
          type: 'line',
          mode: 'vertical',
          scaleID: 'x-axis-1',
          value: this.xPosition,
          borderColor: 'rgba(0, 0, 0, 0.5)',
          borderWidth: 2,
        }]
      },
      scales: {
        xAxes: [{
          scaleLabel: {
            display: true,
            labelString: this.translate.instant('GRAPHIQUE.ABSCISSE')
          }
        }]
      },
      plugins: {
        crosshair: {
          line: {
            color: '#000',
            width: 1
          },
          sync: {
            enabled: true,
            group: 1,
            suppressTooltips: true
          },
          zoom: {
            enabled: false,
          }
        },
        annotation: {
          events: [],
          annotations: [{
            type: 'line',
            mode: 'vertical',
            scaleID: 'x-axis-1',
            value: 2300,
            borderColor: 'rgba(255, 0, 0, 0.5)',
            borderWidth: 4,
            label: {
              enabled: false,
              content: 'Test label'
            },
          }]
        }
      }
    };
  }


  chooseColor() {
    const color = this.listColor[Math.floor(Math.random() * this.listColor.length)];
    if (this.groupOfSimulations.length !== 0) {
      const retour = this.groupOfSimulations.filter((d) => d.genericInformations.color === color);
      if (retour.length === 0) {
        return color;
      } else {
        return this.chooseColor();
      }
    } else {
      return color;
    }
  }


  newSimulation(type, nbrYears, tmpSimulation= null) {
    this.initOptionsGraph();
    const newSimulationDate = moment();
    const newSimulation: simulationModel = new simulationModel();

    let simulationClass: CSimulationValues = new CSimulationValues();
    switch (type) {
      case 'now': {
        simulationClass.create_actual_state();
        break;
      }
      case 'pre-industry': {
        simulationClass.create_1750_state();
        break;
      }
      default: {
        let sv: CSimulationValues = new CSimulationValues();
        sv = this.transcriptSimulationModelToCSimulationValues(this.tmpSimulation);
        simulationClass = sv;
        const ev: CExperienceValues = new CExperienceValues(nbrYears);
        simulationClass.recast_final_initial( sv, ev );
        break;
      }
    }

    // Initialisation des paramètres
    newSimulation.parameters = new simulationParametersModel();
    newSimulation.parameters.emit_anthro_coo_value = simulationClass.emit_anthro_coo_value; // OK
    newSimulation.parameters.volcan_value = simulationClass.volcan_value; // OK
    newSimulation.parameters.alteration_value = simulationClass.alteration_value; // OK
    newSimulation.parameters.stockage_biologique_value = simulationClass.stockage_biologique_value;  // OK
    newSimulation.parameters.fixed_concentration = simulationClass.fixed_concentration; // OK
    newSimulation.parameters.coo_concentr_value = simulationClass.coo_concentr_value;
    newSimulation.parameters.distance_ts_value = simulationClass.distance_ts_value; // OK
    newSimulation.parameters.excentricite_value = simulationClass.excentricite_value; // OK
    newSimulation.parameters.obliquite_value = simulationClass.obliquite_value; // OK
    newSimulation.parameters.puissance_soleil_value = simulationClass.puissance_soleil_value; // OK
    newSimulation.parameters.precession_value = simulationClass.precession_value; // OK
    newSimulation.parameters.fixed_albedo = simulationClass.fixed_albedo; // OK
    newSimulation.parameters.albedo_value = simulationClass.albedo_value; // OK
    newSimulation.parameters.debranche_ocean = simulationClass.debranche_ocean; // OK ????
    newSimulation.parameters.fixed_ocean = simulationClass.fixed_ocean; // OK
    newSimulation.parameters.puit_oce_value = simulationClass.puit_oce_value; // OK
    newSimulation.parameters.debranche_biologie = simulationClass.debranche_biologie; // OK
    newSimulation.parameters.puit_bio_value = simulationClass.puit_bio_value; // OK
    newSimulation.parameters.fixed_eau = simulationClass.fixed_eau; // OK
    newSimulation.parameters.rapport_H2O_value = simulationClass.rapport_H2O_value; // OK

    // console.log(newSimulation.parameters);

    // Initialisation des parametres génériques
    newSimulation.genericInformations = new infoGenericSimulation();
    newSimulation.genericInformations.createDate = newSimulationDate;
    newSimulation.genericInformations.startYear = Number(simulationClass.annee_debut);
    newSimulation.genericInformations.endYear = Number(newSimulation.genericInformations.startYear) + Number(nbrYears);
    newSimulation.genericInformations.historyStartYear = type;
    newSimulation.genericInformations.historyNbrYears = nbrYears;
    this.xPosition = simulationClass.annee_debut;
    this.optionsGraph.annotation.annotations[0].value = this.xPosition;
    this.optionsGraph.plugins.annotation.annotations[0].value = this.xPosition;
    newSimulation.genericInformations.nbrYears = nbrYears;
    newSimulation.select = true;
    newSimulation.isChecked = false;
    newSimulation.genericInformations.name = this.translate.instant('NEW_SIMULATION_TEXT') + this.nbrSimulation;
    newSimulation.genericInformations.color = this.chooseColor();

    // Récupération des résultats
    newSimulation.results = new simulationResultsModel();
    newSimulation.results.emissions_coo_data = simulationClass.emissions_coo_data;
    newSimulation.results.concentrations_coo_data = simulationClass.concentrations_coo_data;
    newSimulation.results.temperature_data = simulationClass.temperature_data;
    newSimulation.results.niveau_calottes_data = simulationClass.niveau_calottes_data;
    newSimulation.results.niveau_mer_data = simulationClass.niveau_mer_data;
    newSimulation.results.albedo_data = simulationClass.albedo_data;
    newSimulation.id = Number(newSimulationDate.format('x'));

    newSimulation.state = 'new';

    this.tmpSimulation = newSimulation;
  }

  async generateSimulation() {
    const loading = await this.loadingController.create();
    await loading.present();

    let simulationClass: CSimulationValues = new CSimulationValues();
    const ev: CExperienceValues = new CExperienceValues(this.tmpSimulation.genericInformations.nbrYears);

    this.tmpSimulation.genericInformations.step = ev.temps_elem_priv;

    simulationClass = this.transcriptSimulationModelToCSimulationValues(this.tmpSimulation);

    // Recalcul de la simulation
    const calculSimulation: CModel = new CModel();
    simulationClass = calculSimulation.modelExecute(simulationClass, ev);
    this.tmpSimulation.results.emissions_coo_data = simulationClass.emissions_coo_data;
    this.tmpSimulation.results.concentrations_coo_data = simulationClass.concentrations_coo_data;
    this.tmpSimulation.results.temperature_data = simulationClass.temperature_data;
    this.tmpSimulation.results.niveau_calottes_data = simulationClass.niveau_calottes_data;
    this.tmpSimulation.results.niveau_mer_data = simulationClass.niveau_mer_data;
    this.tmpSimulation.results.albedo_data = simulationClass.albedo_data;

    if (this.tmpSimulation.state === 'new') {
      this._storage.set('lastSimulation', JSON.stringify(this.tmpSimulation));
      this.lastSimulation = this.tmpSimulation;
      this.tmpSimulation.state = 'edit';
      this.nbrSimulation++;
      this.groupOfSimulations.push(this.tmpSimulation);
    } else {
      if (this.lastSimulation === this.tmpSimulation.id) {
        this._storage.set('lastSimulation', JSON.stringify(this.tmpSimulation));
      }
      this.groupOfSimulations.forEach((s) => {
        if (s.id === this.tmpSimulation.id) {
          s = this.tmpSimulation;
        }
      });
    }
    this.generateGraph();
    await loading.dismiss();
    this.tmpSimulation = null;
    this._nav.navigateRoot(`/simulations-visualization`);

  }


  transcriptSimulationModelToCSimulationValues(simulation: simulationModel) {

    const simulationClass: CSimulationValues = new CSimulationValues();
    // Paramètres
    simulationClass.annee_fin = simulation.genericInformations.endYear;
    simulationClass.annee_debut = simulation.genericInformations.startYear;
    simulationClass.emit_anthro_coo_value = Number(simulation.parameters.emit_anthro_coo_value);
    simulationClass.volcan_value = Number(simulation.parameters.volcan_value);
    simulationClass.alteration_value = Number(simulation.parameters.alteration_value);
    simulationClass.stockage_biologique_value = Number(simulation.parameters.stockage_biologique_value);
    simulationClass.fixed_concentration = simulation.parameters.fixed_concentration;
    simulationClass.coo_concentr_value = Number(simulation.parameters.coo_concentr_value);
    simulationClass.distance_ts_value = Number(simulation.parameters.distance_ts_value);
    simulationClass.excentricite_value = Number(simulation.parameters.excentricite_value);
    simulationClass.obliquite_value = Number(simulation.parameters.obliquite_value);
    simulationClass.puissance_soleil_value = Number(simulation.parameters.puissance_soleil_value);
    simulationClass.precession_value = Number(simulation.parameters.precession_value);
    simulationClass.fixed_albedo = simulation.parameters.fixed_albedo;
    simulationClass.albedo_value = Number(simulation.parameters.albedo_value);
    simulationClass.debranche_ocean = simulation.parameters.debranche_ocean;
    simulationClass.fixed_ocean = simulation.parameters.fixed_ocean;
    simulationClass.puit_oce_value = Number(simulation.parameters.puit_oce_value);
    simulationClass.debranche_biologie = simulation.parameters.debranche_biologie;
    simulationClass.puit_bio_value = Number(simulation.parameters.puit_bio_value);
    simulationClass.fixed_eau = simulation.parameters.fixed_eau;
    simulationClass.rapport_H2O_value = Number(simulation.parameters.rapport_H2O_value);


    simulationClass.init_simulation_arrays( '', 0, 100, 1 );
    // Résultats
    simulationClass.emissions_coo_data.datas = simulation.results.emissions_coo_data.datas;
    simulationClass.emissions_coo_data.imax = simulation.results.emissions_coo_data.imax;
    simulationClass.emissions_coo_data.imin = simulation.results.emissions_coo_data.imin;
    simulationClass.emissions_coo_data.indice_max = simulation.results.emissions_coo_data.indice_max;
    simulationClass.emissions_coo_data.indice_min = simulation.results.emissions_coo_data.indice_min;
    simulationClass.emissions_coo_data.past_datas = simulation.results.emissions_coo_data.past_datas;
    simulationClass.emissions_coo_data.resolution = simulation.results.emissions_coo_data.resolution;

    simulationClass.concentrations_coo_data.datas = simulation.results.concentrations_coo_data.datas;
    simulationClass.concentrations_coo_data.imax = simulation.results.concentrations_coo_data.imax;
    simulationClass.concentrations_coo_data.imin = simulation.results.concentrations_coo_data.imin;
    simulationClass.concentrations_coo_data.indice_max = simulation.results.concentrations_coo_data.indice_max;
    simulationClass.concentrations_coo_data.indice_min = simulation.results.concentrations_coo_data.indice_min;
    simulationClass.concentrations_coo_data.past_datas = simulation.results.concentrations_coo_data.past_datas;
    simulationClass.concentrations_coo_data.resolution = simulation.results.concentrations_coo_data.resolution;

    simulationClass.temperature_data.datas = simulation.results.temperature_data.datas;
    simulationClass.temperature_data.imax = simulation.results.temperature_data.imax;
    simulationClass.temperature_data.imin = simulation.results.temperature_data.imin;
    simulationClass.temperature_data.indice_max = simulation.results.temperature_data.indice_max;
    simulationClass.temperature_data.indice_min = simulation.results.temperature_data.indice_min;
    simulationClass.temperature_data.past_datas = simulation.results.temperature_data.past_datas;
    simulationClass.temperature_data.resolution = simulation.results.temperature_data.resolution;

    simulationClass.niveau_calottes_data.datas = simulation.results.niveau_calottes_data.datas;
    simulationClass.niveau_calottes_data.imax = simulation.results.niveau_calottes_data.imax;
    simulationClass.niveau_calottes_data.imin = simulation.results.niveau_calottes_data.imin;
    simulationClass.niveau_calottes_data.indice_max = simulation.results.niveau_calottes_data.indice_max;
    simulationClass.niveau_calottes_data.indice_min = simulation.results.niveau_calottes_data.indice_min;
    simulationClass.niveau_calottes_data.past_datas = simulation.results.niveau_calottes_data.past_datas;
    simulationClass.niveau_calottes_data.resolution = simulation.results.niveau_calottes_data.resolution;

    simulationClass.niveau_mer_data.datas = simulation.results.niveau_mer_data.datas;
    simulationClass.niveau_mer_data.datas = simulation.results.niveau_mer_data.datas;
    simulationClass.niveau_mer_data.imax = simulation.results.niveau_mer_data.imax;
    simulationClass.niveau_mer_data.imin = simulation.results.niveau_mer_data.imin;
    simulationClass.niveau_mer_data.indice_max = simulation.results.niveau_mer_data.indice_max;
    simulationClass.niveau_mer_data.indice_min = simulation.results.niveau_mer_data.indice_min;
    simulationClass.niveau_mer_data.past_datas = simulation.results.niveau_mer_data.past_datas;
    simulationClass.niveau_mer_data.resolution = simulation.results.niveau_mer_data.resolution;

    simulationClass.albedo_data.datas = simulation.results.albedo_data.datas;
    simulationClass.albedo_data.datas = simulation.results.albedo_data.datas;
    simulationClass.albedo_data.imax = simulation.results.albedo_data.imax;
    simulationClass.albedo_data.imin = simulation.results.albedo_data.imin;
    simulationClass.albedo_data.indice_max = simulation.results.albedo_data.indice_max;
    simulationClass.albedo_data.indice_min = simulation.results.albedo_data.indice_min;
    simulationClass.albedo_data.past_datas = simulation.results.albedo_data.past_datas;
    simulationClass.albedo_data.resolution = simulation.results.albedo_data.resolution;

    return simulationClass;
  }


  deleteSimulation() {
    this.groupOfSimulations = this.groupOfSimulations.filter((s) => s.id != this.tmpSimulation.id);
    this.tmpSimulation = null;
    // this._menu.close();
    if (this.groupOfSimulations.length === 0) {
      this._nav.navigateForward('/home-page');
    } else {
      this.generateGraph();
    }
  }


  saveSimulation(simulation: simulationModel) {
    this._storage.get('listOfSimulation').then((val) => {
      if (val) {
        this.groupOfSaveSimulations = JSON.parse(val);
      }
      this.groupOfSaveSimulations.push(simulation);
      this._storage.set('listOfSimulation', JSON.stringify(this.groupOfSaveSimulations));
    });
  }


  saveSession() {
    let newSession: sessionModel = new sessionModel();
    let simulations: simulationModel[];

    newSession.sessionTimestamp =  moment().format('DD/MM/YYYY hh:mm:ss').toString();
    
    // console.log('New Session: %o', newSession);
    // console.log('Group of simulations: %o', this.groupOfSimulations);

    newSession.sessionSimulations = this.groupOfSimulations;

    // console.log('New Session: %o', newSession);

    this._storage.get('savedSessions').then((val) => {
      if (val)
      {
        this.savedSessions = JSON.parse(val);
      }

      this.savedSessions.push(newSession);

      // console.log('Saved Session: %o', this.savedSessions);
      this._storage.set('savedSessions', JSON.stringify(this.savedSessions));
    });
  }


  loadSessions() {
    this._storage.get('savedSessions').then((val) => {
      this.savedSessions = JSON.parse(val);
    });
  }


  createNewGroup(startYear, nbrYears) {
  }


  // TABLEAU DES VALEURS FIXEES
  readJson() {
    const json = this._http.get('assets/datasFixed.json');
    json.subscribe((s) => {
      this.jsonValues = s;
    });
  }


  readValue(page: string, key: string, value: string = null) {
    const listValues = this.jsonValues;
    if (value === null) {
      return listValues[page][key];
    } else {
      return listValues[page][key][value];
    }
  }
  

  searchConnectionValue(page: string, myKey: string, myValue: any) {
    const listValues = this.jsonValues;
    const connectionValues = listValues[page][myKey];
    let retour: any = 'other';

    switch (page) {
      case 'albedo': {
        if (myKey === 'albedo_value') {
          if (this.tmpSimulation.parameters.fixed_albedo === false) {
            myValue = 'fixed';
          }
        }
      }
      case 'ocean': {
        if (myKey === 'puit_oce_value') {
          if (this.tmpSimulation.parameters.fixed_ocean === false) {
            myValue = 'fixed';
          }
          if (this.tmpSimulation.parameters.debranche_ocean === true) {
            myValue = 'unplug';
          }
        }
      }
      case 'water_vapour': {
        if (myKey === 'rapport_H2O_value') {
          if (this.tmpSimulation.parameters.fixed_eau === false) {
            myValue = 'fixed';
          }
        }
      }
      case 'vegetation': {
        if (myKey === 'puit_bio_value') {
          if (this.tmpSimulation.parameters.debranche_biologie === true) {
            myValue = 'unplug';
          }
        }
      }
    }

    let test = false;

    Object.entries(connectionValues).forEach(([key, data]) => {
      if (data === myValue && test === false) {
        retour = key;
        test = true;
      }
    });

    return retour;
  }


  searchConnectionKey(page: string, myKey: string, myValue: any) {
    const listValues = this.jsonValues;
    const connectionValues = listValues[page][myKey];
    let retour: any = this.readValue(page, myKey, 'minimal');
    switch (page) {
      case 'albedo': {
        if (myKey === 'albedo_value') {
          if (myValue === 'fixed') {
            this.tmpSimulation.parameters.fixed_albedo = false;
          } else {
            this.tmpSimulation.parameters.fixed_albedo = true;
          }
        }
      }
      case 'ocean': {
        if (myKey === 'puit_oce_value') {
          if (myValue === 'fixed') {
            this.tmpSimulation.parameters.fixed_ocean = false;
          } else {
            this.tmpSimulation.parameters.fixed_ocean = true;
          }
          if (myValue === 'unplug') {
            this.tmpSimulation.parameters.debranche_ocean = true;
          } else {
            this.tmpSimulation.parameters.debranche_ocean = false;
          }
        }
      }
      case 'water_vapour': {
        if (myKey === 'rapport_H2O_value') {
          if (myValue === 'fixed') {
            this.tmpSimulation.parameters.fixed_eau = false;
          } else {
            this.tmpSimulation.parameters.fixed_eau = true;
          }
        }
      }
      case 'vegetation': {
        if (myKey === 'puit_bio_value') {
          if (myValue === 'unplug') {
            this.tmpSimulation.parameters.debranche_biologie = true;
          } else {
            this.tmpSimulation.parameters.debranche_biologie = false;
          }
        }
      }
    }
    if (myValue === 'fixed' || myValue === 'other' || myValue === 'unplug') {
      switch (page) {
        case 'albedo': {
          retour = this.tmpSimulation.parameters.albedo_value;
        }
        case 'ocean': {
          retour = this.tmpSimulation.parameters.puit_oce_value;
        }
        case 'vegetation': {
          retour = this.tmpSimulation.parameters.puit_bio_value;
        }
        case 'water_vapour': {
          retour = this.tmpSimulation.parameters.rapport_H2O_value;
        }
      }
    } else {
      Object.entries(connectionValues).forEach(([key, data]) => {
        if (key === myValue) {
          retour = data;
        }
      });
    }
    return retour;
  }


  arround(variable) {
    if (variable != 'fixed' && variable != 'unplug') {
      variable = Math.round(variable * 1000) / 1000;
    }
    return variable;
  }


  // MISE EN PLACE DES GRAPHIQUES
  generateGraph() {
    this.graphDatas_dates();
    this.graphDatas_albedo();
    this.graphDatas_calotte();
    this.graphDatas_concentration();
    this.graphDatas_emissions();
    this.graphDatas_mer();
    this.graphDatas_temperature();
    this.schemaTemperatureByDate();
    this.schemaIceByDate();
    this.schemaWaterByDate();
  }


  calculAxeYears(nbr: number, simulation: simulationModel) {
    const year = {
      numeric : simulation.genericInformations.startYear + (simulation.genericInformations.step * nbr),
      text : Math.round(simulation.genericInformations.startYear + (simulation.genericInformations.step * nbr))
    };
    return year;
  }


  // DATES GRAPH
  graphDatas_dates() {
    this.groupOfSimulations.forEach((s: simulationModel, k) => {
      if (s.select === true) {
        const endDate = Number(s.genericInformations.startYear) + Number(s.genericInformations.nbrYears);
        if (!this.startYears || this.startYears > s.genericInformations.startYear) {
          this.startYears = s.genericInformations.startYear;
          this.xPosition =  this.startYears;
          this.optionsGraph.annotation.annotations[0].value = this.xPosition;
          this.optionsGraph.plugins.annotation.annotations[0].value = this.xPosition;
        }
        if (!this.stopYears || this.stopYears < endDate) {
          this.stopYears = endDate;
        }
      }
    });
  }


  // ALBEDO GRAPH
  graphDatas_albedo() {
    const datasSet: any[] = [];
    this.groupOfSimulations.forEach((s: simulationModel) => {
      if (s.select === true) {
        const datasResult: any[] = [];
        let datasLoop: any;
        if (s.results.albedo_data.past_datas.length != 0) {
          // console.log('past_datas ==> albedo');
        }
        datasLoop = s.results.albedo_data.datas;
        datasLoop.forEach((data , key) => {
          const years = this.calculAxeYears(key, s);
          const datas = {
            x :  years.numeric,
            y : data
          };
          datasResult.push(datas);
        });
        const newLine = {
          label: s.genericInformations.name,
          data: datasResult,
          showLine: true,
          borderColor: s.genericInformations.color,
          backgroundColor: 'transparent',
          pointStyle: 'circle',
          pointBorderWidth: 0,
          pointHitRadius: 0.5,
          pointRadius: 0.5,
          borderWidth: 1,
        };
        datasSet.push(newLine);
      }
    });
    this.graphAlbedo = {
      labels: [],
      datasets: datasSet
    };
  }


  // EMISSIONS COO GRAPH
  graphDatas_emissions() {
    const datasSet: any[] = [];
    this.groupOfSimulations.forEach((s: simulationModel) => {
      if (s.select === true) {
        const datasResult: any[] = [];
        let datasLoop: any;
        if (s.results.emissions_coo_data.past_datas.length != 0) {
          // console.log('past_datas ==> emissions CO2');
        }
        datasLoop = s.results.emissions_coo_data.datas;
        datasLoop.forEach((data , key) => {
          const years = this.calculAxeYears(key, s);
          const datas = {
            x :  years.numeric,
            y : data
          };
          datasResult.push(datas);
        });
        const newLine = {
          label: s.genericInformations.name,
          data: datasResult,
          showLine: true,
          borderColor: s.genericInformations.color,
          backgroundColor: 'transparent',
          pointStyle: 'circle',
          pointBorderWidth: 0,
          pointHitRadius: 0.5,
          pointRadius: 0.5,
          borderWidth: 1,
        };
        datasSet.push(newLine);
      }
    });
    this.graphEmissionsCoo = {
      labels: [],
      datasets: datasSet
    };
  }


  // CONCENTRATION COO GRAPH
  graphDatas_concentration() {
    const datasSet: any[] = [];
    this.groupOfSimulations.forEach((s: simulationModel) => {
      if (s.select === true) {
        const datasResult: any[] = [];
        let datasLoop: any;
        if (s.results.concentrations_coo_data.past_datas.length != 0) {
          // console.log('past_datas ==> concentrations CO2');
        }
        datasLoop = s.results.concentrations_coo_data.datas;
        datasLoop.forEach((data , key) => {
          const years = this.calculAxeYears(key, s);
          const datas = {
            x :  years.numeric,
            y : data
          };
          datasResult.push(datas);
        });
        const newLine = {
          label: s.genericInformations.name,
          data: datasResult,
          showLine: true,
          borderColor: s.genericInformations.color,
          backgroundColor: 'transparent',
          pointStyle: 'circle',
          pointBorderWidth: 0,
          pointHitRadius: 0.5,
          pointRadius: 0.5,
          borderWidth: 1,
        };
        datasSet.push(newLine);
      }
    });

    this.graphConcentrationCoo = {
      labels: [],
      datasets: datasSet
    };
  }


  // TEMPERATURE GRAPH
  graphDatas_temperature() {
    const datasSet: any[] = [];
    this.groupOfSimulations.forEach((s: simulationModel) => {
      if (s.select === true) {
        const datasResult: any[] = [];
        let datasLoop: any;
        if (s.results.temperature_data.past_datas.length != 0) {
          // console.log('past_datas ==> température');
        }
        datasLoop = s.results.temperature_data.datas;
        datasLoop.forEach((data , key) => {
          const years = this.calculAxeYears(key, s);
          const datas = {
            x :  years.numeric,
            y : data
          };
          datasResult.push(datas);
        });
        const newLine = {
          label: s.genericInformations.name,
          data: datasResult,
          showLine: true,
          borderColor: s.genericInformations.color,
          backgroundColor: 'transparent',
          pointStyle: 'circle',
          pointBorderWidth: 0,
          pointHitRadius: 0.5,
          pointRadius: 0.5,
          borderWidth: 1,
        };
        datasSet.push(newLine);
      }
    });

    this.graphTemperature = {
      labels: [],
      datasets: datasSet
    };
    this.schemaTemperatureByDate();
  }


  // NIV CALOTTE GRAPH
  graphDatas_calotte() {
    const datasSet: any[] = [];
    this.groupOfSimulations.forEach((s: simulationModel) => {
      if (s.select === true) {
        const datasResult: any[] = [];
        let datasLoop: any;
        if (s.results.niveau_calottes_data.past_datas.length != 0) {
          // console.log('past_datas ==> niveau calottes');
        }
        datasLoop = s.results.niveau_calottes_data.datas;
        datasLoop.forEach((data , key) => {
          const years = this.calculAxeYears(key, s);
          const datas = {
            x :  years.numeric,
            y : data
          };
          datasResult.push(datas);
        });
        const newLine = {
          label: s.genericInformations.name,
          data: datasResult,
          showLine: true,
          borderColor: s.genericInformations.color,
          backgroundColor: 'transparent',
          pointStyle: 'circle',
          pointBorderWidth: 0,
          pointHitRadius: 0.5,
          pointRadius: 0.5,
          borderWidth: 1,
        };
        datasSet.push(newLine);
      }
    });
    this.graphCalotte = {
      labels: [],
      datasets: datasSet
    };
    this.schemaIceByDate();
  }


  // NIV MER GRAPH
  graphDatas_mer() {
    const datasSet: any[] = [];
    this.groupOfSimulations.forEach((s: simulationModel) => {
      if (s.select === true) {
        const datasResult: any[] = [];
        let datasLoop: any;
        if (s.results.niveau_mer_data.past_datas.length !== 0) {
          // console.log('past_datas ==> niveau mer');
        }
        datasLoop = s.results.niveau_mer_data.datas;
        datasLoop.forEach((data , key) => {
          const years = this.calculAxeYears(key, s);
          const datas = {
            x :  years.numeric,
            y : data
          };
          datasResult.push(datas);
        });

        const newLine = {
          label: s.genericInformations.name,
          data: datasResult,
          showLine: true,
          borderColor: s.genericInformations.color,
          backgroundColor: 'transparent',
          pointStyle: 'circle',
          pointBorderWidth: 0,
          pointHitRadius: 0.5,
          pointRadius: 0.5,
          borderWidth: 1,
        };
        datasSet.push(newLine);
      }
    });
    this.graphMer = {
      labels: [],
      datasets: datasSet
    };
    this.schemaWaterByDate();
  }


  schemaTemperatureByDate() {
    this.arrayTemperature = [];
    let test = false;
    this.graphTemperature.datasets.forEach((gt: any) => {
      test = false;
      const temperature = {
        color: gt.borderColor,
        label: gt.label,
        data: null
      };
      gt.data.forEach((d) => {
        if (d.x >= this.xPosition && test === false) {
          test = true;
          temperature.data = d.y;
          this.arrayTemperature.push(temperature);
        }
      });
    });
  }


  schemaIceByDate() {
    this.arrayIce = [];
    let test = false;
    // console.log(this.graphCalotte);
    this.graphCalotte.datasets.forEach((gt: any) => {
      test = false;
      const ice = {
          color: gt.borderColor,
          label: gt.label,
        data: null,
        percent: null
      };
      gt.data.forEach((d) => {
        if (d.x >= this.xPosition && test === false) {
          test = true;
          ice.data = d.y;
          let data = d.y;
          if (data < -5) {
            data = -5;
          }
          if (data > 5) {
            data = 5;
          }
          data = data + 5;
          ice.percent = data * 10;
          this.arrayIce.push(ice);
        }
      });
    });
  }

  
  schemaWaterByDate() {
    this.arrayWater = [];
    let test = false;
    this.graphMer.datasets.forEach((gt: any) => {
      test = false;
      const water = {
          color: gt.borderColor,
          label: gt.label,
        data: null,
        percent: null
      };
      gt.data.forEach((d) => {
        if (d.x >= this.xPosition && test === false) {
          test = true;
          water.data = d.y;
          let data = d.y;
          if (data < -4.125) {
            data = -4.125;
          }
          if (data > 4.125) {
            data = 4.125;
          }
          data = data + 4.125;
          water.percent = data * 12.121212121212121212121213;
          this.arrayWater.push(water);
        }
      });
    });
  }


  exportCsv(simulations: simulationModel[]) {
    const datasResult: any[] = [];
    simulations.forEach((s: simulationModel) => {
      let datasLoop: any;
      datasLoop = s.results.emissions_coo_data.datas;
      datasLoop.forEach((data , key) => {
        const years = this.calculAxeYears(key, s);
        const datas = {
          year :  years.numeric,
          emissions_coo_data : s.results.emissions_coo_data.datas[key],
          concentrations_coo_data : s.results.concentrations_coo_data.datas[key],
          temperature_data : s.results.temperature_data.datas[key],
          niveau_calottes_data : s.results.niveau_calottes_data.datas[key],
          niveau_mer_data : s.results.niveau_mer_data.datas[key],
          albedo_data : s.results.albedo_data.datas[key]
        };
        datasResult.push(datas);
      });
      const options = {
        fieldSeparator: ';',
        quoteStrings: '"',
        decimalseparator: '.',
        showTitle: true,
        title: s.genericInformations.name,
        headers: [
            this.translate.instant('EXPORT.YEAR'),
            this.translate.instant('EXPORT.EMISSION'),
            this.translate.instant('EXPORT.CONCENTRATION'),
            this.translate.instant('EXPORT.TEMPERATURE'),
            this.translate.instant('EXPORT.ICE_CAP'),
            this.translate.instant('EXPORT.OCEAN_HEIGHT'),
            this.translate.instant('EXPORT.ALBEDO')
        ]
      };
      // tslint:disable-next-line:no-unused-expression
      new ngxCsv(datasResult, options.title, options);
    });
  }
}