import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IceComponent } from './ice/ice.component';
import { WaterComponent } from './water/water.component';

import { ShareModule } from '../share/share.module';



@NgModule({
  declarations: [IceComponent,WaterComponent],
  imports: [
    CommonModule,
    ShareModule
  ],
  exports: [IceComponent,WaterComponent]
})
export class SchemaModule { }
