<div class="schema">
  <img src="{{imageDirectory}}schema_calottes_0.svg"  *ngIf="this._simulations.arrayIce[0].data<=1"/>
  <img src="{{imageDirectory}}schema_calottes_5.svg"  *ngIf="this._simulations.arrayIce[0].data>1 && this._simulations.arrayIce[0].data<=7.5" />
  <img src="{{imageDirectory}}schema_calottes_10.svg" *ngIf="this._simulations.arrayIce[0].data>7.5 && this._simulations.arrayIce[0].data<=12.5" />
  <img src="{{imageDirectory}}schema_calottes_15.svg" *ngIf="this._simulations.arrayIce[0].data>12.5 && this._simulations.arrayIce[0].data<=17.5" />
  <img src="{{imageDirectory}}schema_calottes_20.svg" *ngIf="this._simulations.arrayIce[0].data>17.5 && this._simulations.arrayIce[0].data<=22.5" />
  <img src="{{imageDirectory}}schema_calottes_25.svg" *ngIf="this._simulations.arrayIce[0].data>22.5 && this._simulations.arrayIce[0].data<=27.5" />
  <img src="{{imageDirectory}}schema_calottes_30.svg" *ngIf="this._simulations.arrayIce[0].data>27.5 && this._simulations.arrayIce[0].data<=35.5" />
  <img src="{{imageDirectory}}schema_calottes_35.svg" *ngIf="this._simulations.arrayIce[0].data>35.5 && this._simulations.arrayIce[0].data<=37.5" />
  <img src="{{imageDirectory}}schema_calottes_40.svg" *ngIf="this._simulations.arrayIce[0].data>37.5 && this._simulations.arrayIce[0].data<=45.0" />
  <img src="{{imageDirectory}}schema_calottes_50.svg" *ngIf="this._simulations.arrayIce[0].data>45.0 && this._simulations.arrayIce[0].data<=60.0" />
  <img src="{{imageDirectory}}schema_calottes_70.svg" *ngIf="this._simulations.arrayIce[0].data>60.0 && this._simulations.arrayIce[0].data<=85.0" />
  <img src="{{imageDirectory}}schema_calottes_90.svg" *ngIf="this._simulations.arrayIce[0].data>85.0" />
</div>
