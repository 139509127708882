import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { NavController } from '@ionic/angular';
import { SimulationsService } from '../../share/simulations/simulations.service';
import { infoGenericSimulation } from '../../share/simulations/simulations.model';
import { LanguageService } from '../../share/language.service';

@Component({
  selector: 'app-editForm',
  templateUrl: './single-view.component.html',
  styleUrls: ['./single-view.component.scss'],
})
export class SingleViewComponent implements OnInit {

  // Utilité ?
  listColor: string[] = [
    '#F44336',
    '#9C27B0',
    '#3F51B5',
    '#03A9F4',
    '#009688',
    '#8BC34A',
    '#FF9800',
    '#795548',
    '#607D8B',
    '#B0B848',
    '#D4A373',
    '#FF70A6',
    '#FFDC5E',
    '#ECA77F',
    '#61A1D7',
    '#BC0848',
    '#248520',
    '#55D6C2',
    '#F65BE3',
    '#80FF72'
  ];

  infosGenerics: infoGenericSimulation = new infoGenericSimulation();
  @Input() genericInformations: infoGenericSimulation = new infoGenericSimulation();
  @Input() type = 'free';
  @Output() action = new EventEmitter();

  constructor(
    public translate: TranslateService,
    public _nav: NavController,
    public _simulations: SimulationsService,
    public _language: LanguageService) 
  {
      translate.addLangs(['en', 'fr', 'es', 'de']);
      this._language.setInitialAppLanguage();
      this.listColor = this._simulations.listColor;
  }

  ngOnInit() {
    this.infosGenerics = JSON.parse(JSON.stringify(this.genericInformations));
  }

  onChange($event, type: string) {
    switch (type) {
      case 'name': {
        const retour = this._simulations.groupOfSimulations.filter((d) => d.genericInformations.name === $event && d.id != this._simulations.tmpSimulation.id);
        if (retour.length != 0) {
          this._simulations.tmpSimulation.genericInformations.name = this._simulations.tmpSimulation.genericInformations.name + ' ' + this._simulations.nbrSimulation;
        }
      }
    }
  }

  thisColor(color) {
    const retour = this._simulations.groupOfSimulations.filter((d) => d.genericInformations.color === color && d.id != this._simulations.tmpSimulation.id);
    if (retour.length === 0) {
      this._simulations.tmpSimulation.genericInformations.color = color;
    }
  }

  testColorUse(color) {
    const retour = this._simulations.groupOfSimulations.filter((d) => d.genericInformations.color === color && d.id != this._simulations.tmpSimulation.id);

    if (retour.length === 0) {
      return false;
    } else {
      return true;
    }
  }

  linkParameters() {
    if (this._simulations.tmpSimulation.state === 'new') { 
      // this._simulations.tmpSimulation.state = "edit";
    }
    this.action.emit('return');
  }
}
