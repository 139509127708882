import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListViewComponent } from './list-view/list-view.component';
import { IonicModule } from '@ionic/angular';
import { MomentModule } from 'ngx-moment';

import { ShareModule } from '../share/share.module';


@NgModule({
  declarations: [
    ListViewComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    MomentModule,
    ShareModule
  ],
  exports: [
    ListViewComponent
  ],
})
export class SavedStateModule { }
