import { Injectable } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { HttpClient,HttpHeaders, HttpRequest } from '@angular/common/http';
import { LanguageService } from '../share/language.service'
import isElectron from 'is-electron';


@Injectable({
  providedIn: 'root'
})
export class FaqsService {

  browserLang: string = 'fr';
  contentsFaqs = null;

  constructor(public translate: TranslateService,
              public _http: HttpClient,
              public _language: LanguageService) {
        this.getContent().subscribe((s) => {
          this.contentsFaqs = s;
        });
  }
  getContent(){
    if (isElectron())
    {
      return this._http.get('assets/i18n/faqs_'+this._language.selected+'.electron.json');
    }
    else
    {
      return this._http.get('assets/i18n/faqs_'+this._language.selected+'.json');
    }
  }

  isExist(section, page){
    if(this.contentsFaqs && this.contentsFaqs[section] && this.contentsFaqs[section][page] && this.contentsFaqs[section][page].content_html){
      return true;
    }else{
      return false;
    }

  }
}
