import { Component, OnInit, OnChanges, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../../share/language.service';
import { SimulationsService } from '../../share/simulations/simulations.service';
import { simulationModel } from '../../share/simulations/simulations.model';
import isElectron from 'is-electron';

@Component({
  selector: 'app-schema-ice',
  templateUrl: './ice.component.html',
  styleUrls: ['./ice.component.scss'],
})
export class IceComponent implements OnInit {

    @Input() year;

    imageDirectory : string;

  constructor(public translate: TranslateService,
    public _language: LanguageService,
    public _simulations: SimulationsService) {
      translate.addLangs(['en', 'fr', 'es', 'de']);
      this._language.setInitialAppLanguage();
      
      if (isElectron())
      {
        this.imageDirectory = './assets/imgs/animations/';
      }
      else
      {
        this.imageDirectory = '/assets/imgs/animations/';
      }
  }

  ngOnInit() {
  }
  ngOnChanges(changes){
    this._simulations.schemaIceByDate();
  }

}
