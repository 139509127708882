import { Component, OnInit, Input } from '@angular/core';
import { FaqsService } from '../faqs.service';

@Component({
  selector: 'app-faqs',
  templateUrl: './single-view.component.html',
  styleUrls: ['./single-view.component.scss'],
})
export class SingleViewComponent implements OnInit {

  @Input() section: string = null;
  @Input() page: string = null;

  datasPage : any = {
    "content" : null,
    "content_html" : null
  };

  constructor( public _faqsService: FaqsService) { }

  ngOnInit() {
    this._faqsService.getContent().subscribe((s) => {
      if(s && s[this.section] && s[this.section][this.page]){
        this.datasPage = s[this.section][this.page];
      }else{
        this.datasPage = null;
      }
    }, (e) => { 
      this.datasPage = null;
    });
  }

}
