import { Injectable } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { HttpClient,HttpHeaders, HttpRequest } from '@angular/common/http';
import { LanguageService } from '../share/language.service';
import isElectron from 'is-electron';


@Injectable({
  providedIn: 'root'
})
export class ContentsTextService {

  browserLang: string = 'fr';

  constructor(public translate: TranslateService,
              public _http: HttpClient,
              public _language: LanguageService) {
    
  }

  getContent(){
    if (isElectron())
    {
      return this._http.get('assets/i18n/pages_'+this._language.selected+'.electron.json');
    }
    else
    {
      return this._http.get('assets/i18n/pages_'+this._language.selected+'.json');
    }
  }
}
