<form class="{{type}}">
  <div class="field">
    <ion-label [innerHTML]="'SIMULATION_PARAMETERS.EDIT.FORM.SIMULATION_NAME' | translate" *ngIf="type!='pop'">Nom de la simulation</ion-label>
    <ion-input name="simulation_name" [(ngModel)]="_simulations.tmpSimulation.genericInformations.name" (ngModelChange)="onChange($event, 'name')"  ></ion-input>
  </div>
  <div class="field">
    <ion-label [innerHTML]="'SIMULATION_PARAMETERS.EDIT.FORM.SIMULATION_COLOR' | translate" *ngIf="type!='pop'">Couleur de la simulation</ion-label>
    <ul class="colorsChoice">
      <ng-container *ngFor="let color of listColor">
          <li *ngIf="!testColorUse(color)" (click)="thisColor(color)" [ngClass]="{'active': color===_simulations.tmpSimulation.genericInformations.color}" [ngStyle]="{'background' : color}"></li>
      </ng-container>
    </ul>
  </div> 
  <div class="field button" *ngIf="type==='free'">
    <ion-button color="secondary" [innerHTML]="'SIMULATION_PARAMETERS.EDIT.FORM.PARAMETERS' | translate" (click)="linkParameters()">...</ion-button>
  </div> 
</form>