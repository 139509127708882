import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Storage } from '@ionic/storage';
import { SimulationsService } from '../../share/simulations/simulations.service';
import { SimulationsDatas } from '../../share/simulations/simulations.datas';
import { listOfsimulationsModel, infoGenericSimulation, simulationModel } from '../../share/simulations/simulations.model';

@Component({
  selector: 'app-list-view',
  templateUrl: './list-view.component.html',
  styleUrls: ['./list-view.component.scss'],
})
export class ListViewComponent implements OnInit {

  listSavedSimulations: simulationModel[] = [];
  groupOfSaveSimulations : simulationModel[] = [];
  
  @Output() chooseSavedSimulation = new EventEmitter();

  constructor(public _storage: Storage,
    public _simulation: SimulationsService) { }

  ngOnInit() {
    this._storage.get('listOfSimulation').then((val) => {
      this._simulation.groupOfSaveSimulations = JSON.parse(val);
      this.listSavedSimulations = this._simulation.groupOfSaveSimulations;
    });
  }

  calculLastYear(simulation: infoGenericSimulation){
    return simulation.startYear+simulation.nbrYears;
  }

}
